
.video-management {
  .header-box {
    ::v-deep .el-button {
      border: none;
      background-color: #594fee;
      color: #fff;
    }
  }

  // 弹框
  ::v-deep(.el-dialog) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 960px;
    height: 540px;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 100%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
    }
  }

  .content-box {
    margin-top: 20px;
    min-height: calc(100vh - 235px);
    background-color: #ffffff;
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;

    .content-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-text {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #252632;
        text-align: left;
        font-style: normal;
      }

      .title-filter-box {
        display: flex;
        gap: 6px;

        ::v-deep .el-input__inner {
          background-color: #f4f5f5;
          height: 36px;
          line-height: 36px;

          &:focus,
          &:hover {
            outline: none;
            border-color: #dcdfe6;
          }
        }

        .filter-item {
          background-color: #f2f2f4;
          border-radius: 4px;
          padding: 2px 12px;
          display: flex;
          gap: 2px;
          align-items: center;
          height: 36px;
          box-sizing: border-box;

          span {
            text-wrap: nowrap;
            color: rgba(37, 38, 50, 0.6);
          }

          ::v-deep .el-select__tags-text {
            max-width: 180px !important;
            flex-wrap: nowrap;
            display: inline-block;
            max-width: 100px !important; // 根据实际情况调整
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 超出文本以省略号显示
            white-space: nowrap; // 文本不换行
          }

          ::v-deep .el-input--suffix {
            width: 212px;
          }

          ::v-deep .el-input__inner {
            padding-left: 10px;
            background: transparent;
            border: none;
          }

          ::v-deep .el-date-editor .el-range-input {
            background-color: transparent;
          }

          ::v-deep .el-date-editor--daterange.el-input,
          .el-date-editor--daterange.el-input__inner,
          .el-date-editor--timerange.el-input,
          .el-date-editor--timerange.el-input__inner {
            width: 250px;
          }
        }
      }
    }

    .content-main {
      .main-header {
        position: relative;

        .video-count {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);

          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #888888;
          line-height: 14px;
          font-style: normal;
        }

        ::v-deep .el-menu-item {
          padding-inline: 3px;
          margin-right: 20px;
        }
      }

      .main-no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 40px;

        img {
          width: 200px;
          height: 200px;
          object-fit: cover;
        }

        span {
          text-align: center;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: rgba(28, 31, 35, 0.55);
          line-height: 16px;
          font-style: normal;
        }
      }

      .main-list {
        min-height: calc(100vh - 385px);
        box-sizing: border-box;
        padding: 10px 0;

        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }

        .video-card {
          display: flex;
          justify-content: space-between;
          border-radius: 4px;
          gap: 10px;
          padding: 12px;

          &:hover {
            background-color: #f8f8f8;
          }

          & > div {
            flex-grow: 0;
          }

          .empty {
            flex-grow: 1 !important;
          }

          .card-cover {
            position: relative;
            img {
              width: 150px;
              height: 200px;
              object-fit: cover;
              border-radius: 5px;
              cursor: pointer;
            }

            .sticky-top {
              position: absolute;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 12px;
              color: #161823;
              line-height: 14px;
              font-style: normal;
              background-color: #ffd107;
              border-radius: 2px;
              padding: 4px;
              top: 5px;
              left: 5px;
            }

            .preview-btn {
              display: inline-block;
              position: absolute;
              width: 13px;
              height: 13px;
              box-sizing: border-box;
              top: 10px;
              right: 5px;
              background-color: #ffffff;
              border-radius: 3px;
              z-index: 2;
              // cursor: pointer;

              &::before {
                content: "";
                position: absolute;
                background-color: #ffffff;
                width: 13px;
                height: 13px;
                bottom: 15%;
                right: 15%;
                border-radius: 3px;
                border-right: 1px solid #c7b9b9;
                border-bottom: 1px solid #d9d9d9;
                box-sizing: border-box;
              }
            }
          }

          .card-info {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            & > div {
              flex-grow: 0;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              text-align: left;
              font-style: normal;
            }

            &_desc {
              font-size: 14px;
              color: #252632;
              line-height: 16px;

              display: -webkit-box;
              line-clamp: 2;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
              margin-bottom: 5px;

              &.no-data {
                color: rgba(28, 31, 35, 0.55);
              }
            }

            &_date {
              font-size: 12px;
              color: rgba(28, 31, 35, 0.6);
              line-height: 14px;
              margin-bottom: 10px;
            }

            &_status {
              &.success {
                font-size: 12px;
                color: #39b54a;
                line-height: 14px;
              }

              &.info {
                font-size: 12px;
                color: rgba(28, 31, 35, 0.3);
                line-height: 14px;
              }

              &.error {
                font-size: 12px;
                color: #fe2c55;
                line-height: 14px;
              }
            }

            &_counts {
              display: flex;
              align-items: center;

              .count-item {
                width: 80px;
                position: relative;
                margin-right: 12px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                gap: 6px;

                & > span {
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 500;
                  text-align: left;
                  font-style: normal;
                  text-transform: none;
                }

                .item-text {
                  font-size: 12px;
                  color: rgba(22, 24, 35, 0.34);
                  line-height: 14px;
                }

                .item-count {
                  font-weight: bold;
                  font-size: 14px;
                  color: #161823;
                  line-height: 16px;
                }

                &:not(.count-item:last-of-type)::after {
                  content: "";
                  position: absolute;
                  width: 1px;
                  height: 24px;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                  background-color: #e3e3e4;
                }
              }
            }
          }

          .card-operation {
            ::v-deep .el-button {
              margin-left: 20px !important;
            }

            ::v-deep .el-button--text {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: rgba(28, 31, 35, 0.6);
              line-height: 16px;
            }

            .delete-button {
              color: #f56c6c;

              svg path {
                fill: #f56c6c !important;
              }
            }
          }
        }
      }
    }
    .medio-load {
      display: flex;
      justify-content: center;
      padding: 25px 0;

      span {
        font-weight: 400;
        font-size: 16px;
        color: #888888;
        line-height: 19px;
        cursor: pointer;
      }
    }
  }
}
